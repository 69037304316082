<template>
  <!--begin::Modal - View tenants-->
  <div class="modal fade" id="kt_modal_view_tenants" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div
          class="modal-header pb-0 border-0 justify-content-end"
          v-if="router.currentRoute.value.name !== 'sign-in'"
        >
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            v-if="router.currentRoute.value.name !== 'sign-in'"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-18 py-15">
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1 class="mb-3">Browse Tenants</h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5">
              Please select your preferred tenant.
              <br />
              <em>
                <strong>NOTE:</strong> You can change this later in your profile.
              </em>
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!--begin::Users-->
          <div class="mb-15">
            <div class="d-flex flex-stack py-5" v-if="currentTenant">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-35px symbol-circle">
                  <span
                    class="symbol-label fw-bold"
                  >{{ currentTenant.tenant.charAt(0).toUpperCase() }}</span>
                </div>
                <!--end::Avatar-->

                <!--begin::Details-->
                <div class="ms-6">
                  <!--begin::Name-->
                  <div class="d-flex align-items-center fs-5 fw-bolder text-dark text-capitalize">
                    {{ currentTenant.tenant }}
                    <span
                      class="badge badge-light fs-8 fw-bold ms-2"
                    >{{ checkCurrentTenant(currentTenant.tenant) }}</span>
                  </div>
                  <!--end::Name-->

                  <!--begin::Email-->
                  <div class="fw-bold text-gray-400">Owner: admin@test.com</div>
                  <!--end::Email-->
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Stats-->
              <div class="d-flex">
                <!--begin::Sales-->
                <div class="text-end">
                  <button
                    :disabled="router.currentRoute.value.name !== 'sign-in' && checkCurrentTenant(currentTenant.tenant) ? true : false"
                    class="btn btn-sm btn-primary"
                    @click="setPreferredTenant(currentTenant.tenant)"
                  >
                    {{
                      confirmTenantBtnMsg()
                    }}
                  </button>
                </div>
                <!--end::Sales-->
              </div>
              <!--end::Stats-->
            </div>

            <!--begin::Separator-->
            <div class="d-flex align-items-center mb-5">
              <div class="border-bottom border-gray-300 mw-50 w-100"></div>
              <span class="fw-bold text-gray-400 fs-7 mx-2">OR</span>
              <div class="border-bottom border-gray-300 mw-50 w-100"></div>
            </div>
            <!--end::Separator-->

            <!--begin::List-->
            <div class="mh-375px scroll-y me-n7 pe-7">
              <div class="fs-6 fw-bold mb-2">Other Available Tenant(s)</div>
              <template v-for="(tenant, index) in availableTenants" :key="index">
                <!--begin::User-->
                <div
                  class="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed"
                >
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle">
                      <span class="symbol-label fw-bold">{{ tenant.tenant.charAt(0).toUpperCase() }}</span>
                    </div>
                    <!--end::Avatar-->

                    <!--begin::Details-->
                    <div class="ms-6">
                      <!--begin::Name-->
                      <div class="d-flex align-items-center fs-5 fw-bolder text-dark">
                        {{ capitalizeText(tenant.tenant) }}
                        <span
                          class="badge badge-light fs-8 fw-bold ms-2"
                        >{{ checkCurrentTenant(tenant.tenant) }}</span>
                      </div>
                      <!--end::Name-->

                      <!--begin::Email-->
                      <div class="fw-bold text-gray-400">Owner: admin@test.com</div>
                      <!--end::Email-->
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--begin::Stats-->
                  <div class="d-flex">
                    <!--begin::Sales-->
                    <div class="text-end">
                      <button
                        :disabled="checkCurrentTenant(tenant.tenant) ? true : false"
                        class="btn btn-sm btn-primary"
                        @click="setPreferredTenant(tenant.tenant)"
                      >
                        {{
                          confirmTenantBtnMsg()
                        }}
                      </button>
                    </div>
                    <!--end::Sales-->
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::User-->
              </template>
            </div>
            <!--end::List-->
          </div>
          <!--end::Users-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - View Users-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { capitalizeText } from "@/core/helpers/config";
import { Actions } from "@/store/enums/StoreEnums";
import { User } from "@/core/enums/UserEnums";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "view-tenants-modal",
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const selectedTenant = ref('');

    const confirmTenantBtnMsg = () => {
      return router.currentRoute.value.name === 'sign-in' ? 'Sign In with this tenant' : 'Switch to the this tenant'
    }

    const user = computed(() => {
      return store.getters.currentUser;
    })

    const setPreferredTenant = (tenant) => {
      store.dispatch(Actions.SAVE_PREFERRED_TENANT, {
        tenant: tenant,
        [User.userId]: user.value.id
      }).then(() => {
        hideModal('kt_modal_view_tenants')
        console.log(route.query.redirect)
        router.replace(`${route.query.redirect || '/dashboard'}`)
      })
    }

    const availableTenants = computed(() => {
      return store.getters.availableTenants.filter(t => t.tenant !== user.value.tenant);
    })

    const currentTenant = computed(() => {
      return store.getters.availableTenants.filter(t => t.tenant === user.value.tenant)[0];
    })

    const checkCurrentTenant = (tenant) => {
      if (tenant === user.value.tenant) {
        return 'Current Preferred Tenant'
      }
    }

    return {
      setPreferredTenant,
      checkCurrentTenant,
      availableTenants,
      capitalizeText,
      currentTenant,
      confirmTenantBtnMsg,
      router
    };
  }
});
</script>



