
import { defineComponent, ref, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import ViewTenantsModal from "@/components/modals/general/ViewTenantsModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { showModal } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/config";
import Multiselect from '@vueform/multiselect';
import { useReCaptcha } from 'vue-recaptcha-v3'

enum Language {
  en = 'en',
  et = 'ee',
  au = 'au',
  de = 'de',
  ru = 'ru',
  lt = 'lt',
  uk = 'uk'
}

export default defineComponent({
  name: "sign-in",
  props: ['email'],
  components: {
    Field,
    Form,
    Multiselect,
    ErrorMessage,
    ViewTenantsModal
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const submitButton = ref<HTMLElement | null>(null);
    const reCaptcha = useReCaptcha();
    const languageDropdown = reactive({
      mode: "single",
      caret: true,
      closeOnSelect: true,
      canDeselect: false,
      canClear: false,
      object: false,
      value: 'ee',
      options: [
        {
          value: 'en',
          label: "English",
          icon: "media/flags/united-states.svg"
        },
        {
          value: 'ee',
          label: "Eesti",
          icon: "media/flags/estonia.svg"
        },
        {
          value: 'de',
          label: "Deutsch",
          icon: "media/flags/germany.svg"
        },
        {
          value: 'au',
          label: "Australia",
          icon: "media/flags/australia.svg"
        },
        {
          value: 'lt',
          label: "Lietuvių",
          icon: "media/flags/lithuania.svg"
        },
        {
          value: 'ru',
          label: "Pусский",
          icon: "media/flags/russia.svg"

        },
        {
          value: 'uk',
          label: "Yкраїнська",
          icon: "media/flags/ukraine.svg"
        },
      ],
      trackBy: "value",
      label: "label",
    });

    const recaptcha = async () => {
      await reCaptcha!.recaptchaLoaded();

      const token = await reCaptcha!.executeRecaptcha('submit');

      const userVerified = await store.dispatch(Actions.RECAPTCHA_VERIFY, token);
      return userVerified
    }

    //Create form validation object
    const login = Yup.object().shape({
      email:
        Yup
          .string()
          .required('signInPage.emailRequired'),
      password:
        Yup
          .string()
          .min(6, 'signInPage.passwordMin6')
          .required('signInPage.passwordRequired'),
    });

    //Form submit function
    const onSubmitLogin = async (values): Promise<void> => {
      // const { data } = await recaptcha()

      // if ((!data.success || data.score <= 0.5) && process.env.VUE_APP_ENABLE_RECAPTCHA) return;

      if (submitButton.value) {
        loading.value = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(async () => {
          loading.value = false;
          if (store.getters.availableTenants.length === 0 && !store.getters.currentUser.tenant) {
            console.log(route.query.redirect)
            return router.replace(`${route.query.redirect || '/contracts'}`);
          }

          if (store.getters.availableTenants.length > 1) {
            submitButton.value?.removeAttribute("data-kt-indicator");
            showModal('kt_modal_view_tenants', false, 'static')
          } else {
            console.log(route.query.redirect)
            router.replace(`${route.query.redirect || '/dashboard'}`)
          }

        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(function () {
            loading.value = false;
            // //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
          });
        });
    };

    const handleLocaleChanges = (value) => {
      i18n.locale.value = value
      localStorage.setItem('lang', value)
    }

    onMounted(() => {
      let language;

      if (localStorage.getItem('lang') === 'undefined' || !localStorage.getItem('lang')) {
        language = window.navigator['userLanguage'] || window.navigator.language;
      } else {
        language = localStorage.getItem('lang')
      }

      const locale = language.toLowerCase();
      languageDropdown.value = locale === 'en-au' ? 'au' : Language[locale.split("-")[0]] || 'ee'
      handleLocaleChanges(languageDropdown.value)
    })

    return {
      onSubmitLogin,
      login,
      submitButton,
      loading,
      translate,
      languageDropdown,
      handleLocaleChanges,
    };
  },
});
