
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { capitalizeText } from "@/core/helpers/config";
import { Actions } from "@/store/enums/StoreEnums";
import { User } from "@/core/enums/UserEnums";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "view-tenants-modal",
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const selectedTenant = ref('');

    const confirmTenantBtnMsg = () => {
      return router.currentRoute.value.name === 'sign-in' ? 'Sign In with this tenant' : 'Switch to the this tenant'
    }

    const user = computed(() => {
      return store.getters.currentUser;
    })

    const setPreferredTenant = (tenant) => {
      store.dispatch(Actions.SAVE_PREFERRED_TENANT, {
        tenant: tenant,
        [User.userId]: user.value.id
      }).then(() => {
        hideModal('kt_modal_view_tenants')
        console.log(route.query.redirect)
        router.replace(`${route.query.redirect || '/dashboard'}`)
      })
    }

    const availableTenants = computed(() => {
      return store.getters.availableTenants.filter(t => t.tenant !== user.value.tenant);
    })

    const currentTenant = computed(() => {
      return store.getters.availableTenants.filter(t => t.tenant === user.value.tenant)[0];
    })

    const checkCurrentTenant = (tenant) => {
      if (tenant === user.value.tenant) {
        return 'Current Preferred Tenant'
      }
    }

    return {
      setPreferredTenant,
      checkCurrentTenant,
      availableTenants,
      capitalizeText,
      currentTenant,
      confirmTenantBtnMsg,
      router
    };
  }
});
